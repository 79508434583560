export default function () {
    const selectAll = Array.from(document.querySelectorAll('.js-select'));
    if (!selectAll.length) return;

    selectAll.forEach((select) => {
        select.addEventListener('change', (e) => {
            e.preventDefault();

            try {
                const anchorId = e.target.dataset.anchor || '#channels';
                Turbolinks.visit(e.target.value + anchorId);
            } catch (e) {
                console.error(e)
            }
        })
    })
}
