export default function() {
    var $sliderItem = $(".slider__item");
    if (!$sliderItem.length) return;
    var CLASS_COLUMN_WRAP = "slides__column-wrap";
    var CLASS_COLUMN_WRAP_INNER = "slides__column-wrap-inner";
    var CLASS_SLIDER_ITEM = "slider__item";
    var CLASS_SLIDER_ITEM_WRAP = "slider__item-wrap";
    var CLASS_SLIDER_ITEM_ACTIVE = "slider__item_active";
    var CLASS_SLIDER_ITEM_ABSOLUTE = "slider__item_absolute";
    var CLASS_SLIDER_ITEM_LEFT_0_IMPORTANT = "slider__item_left-0-important";
    var CLASS_SLIDER_COLUMN = "slider-column";
    var $columnWrap = $('.' + CLASS_COLUMN_WRAP);
    var $sliderItemWrapStart;
    var zIndex = 5;
    var slideCount = 99;
    var TRANSLATE_TIME = 250;
    var ANIMATE_TIME = 700;
    var TIME_AUTOPLAY = 2000;
    var CLASS_SLIDES = 'slides';
    var $slidesClone;
    var visibilityCount;

    function getVisibilityCount() {
        if (visibilityCount) return visibilityCount;
        var $columnWrapWidth = $columnWrap.width();
        var $sliderItemWrapWidth = $('.' + CLASS_SLIDER_ITEM_WRAP).outerWidth(true);
        return Math.ceil($columnWrapWidth / $sliderItemWrapWidth);
    }

    function startAutoplay() {
        var lastSlideActive = $('.' + CLASS_SLIDER_ITEM_ACTIVE).last();
        var sliderItemAll = $('.' + CLASS_SLIDER_ITEM);
        var indexLastSlideActive = sliderItemAll.index(lastSlideActive);
        var nextSliderItem = sliderItemAll.eq(indexLastSlideActive + 1);
        onColumnWrapClick(null, nextSliderItem);
    }

    function addAutoplay() {
        window.sliderIntervalId = setInterval(function () {
            startAutoplay()
        }, TIME_AUTOPLAY);
        document.addEventListener('turbolinks:before-cache', function() {
            if (window.sliderIntervalId) {
                clearInterval(window.sliderIntervalId)
            }
        })
    }

    function cloneSlidesOnStart() {
        $slidesClone = $('.' + CLASS_SLIDES).clone();
        $sliderItemWrapStart= $('.' + CLASS_SLIDER_ITEM_WRAP);
        if ((getVisibilityCount() * 3) > $sliderItemWrapStart.length) {
            var counterMax = (getVisibilityCount() * 3) - $sliderItemWrapStart.length;
            var counter = Math.floor(counterMax / $sliderItemWrapStart.length) * $sliderItemWrapStart.length;
            var $columnWrapInner = $('.' + CLASS_COLUMN_WRAP_INNER);
            for (var i = 0; i < counter; i++) {
                var itemEq = (i >= $sliderItemWrapStart.length) ? (i - Math.floor(i / $sliderItemWrapStart.length) * $sliderItemWrapStart.length) : i;
                $sliderItemWrapStart.eq(itemEq).clone(true).appendTo($columnWrapInner)
            }
        }

        window.sliderTimerIdAutoplay = setTimeout(function () {
            addAutoplay();
        }, 2000);
    }

    cloneSlidesOnStart();

    function cloneSlideOnClick($sliderItem) {
        var $sliderItemAll= $('.' + CLASS_SLIDER_ITEM_WRAP).find('.' + CLASS_SLIDER_ITEM);
        var $columnWrapInner = $('.' + CLASS_COLUMN_WRAP_INNER);

        var indexItem = $sliderItemAll.index($sliderItem); // индекс в массиве $sliderItemWrap
        var diffIndex = indexItem + getVisibilityCount() * 1.5 - $sliderItemAll.length; // сколько добавить

        if (diffIndex > 0) {
            for (var i = 0; i <= diffIndex; i++) {
                $sliderItemAll.eq(i)
                    .removeClass(CLASS_SLIDER_ITEM_ABSOLUTE)
                    .removeClass(CLASS_SLIDER_ITEM_ACTIVE)
                    .removeClass(CLASS_SLIDER_ITEM_LEFT_0_IMPORTANT)
                    .css('left', '');
                $sliderItemAll.eq(i).find('.' + CLASS_SLIDER_COLUMN).css('width', '');
                var $newWrap = $("<div class='slider__item-wrap'></div>");
                $sliderItemAll.eq(i).appendTo($newWrap);
                $newWrap.appendTo($columnWrapInner);
            }
        }
    }

    if (!$sliderItem.length) return;

    function openSlider($target, $columnWrapInner, diff) {
        var $columnLast = $('.' + CLASS_SLIDER_ITEM_ACTIVE).find('.' + CLASS_SLIDER_COLUMN);

        $target.addClass(CLASS_SLIDER_ITEM_ACTIVE).addClass(CLASS_SLIDER_ITEM_ABSOLUTE).addClass(CLASS_SLIDER_ITEM_LEFT_0_IMPORTANT);
        $target.css('z-index', zIndex++);
        var $columnNow = $target.find('.' + CLASS_SLIDER_COLUMN);
        $columnNow.width($columnLast.width());
        var columnOuterWidth = $target.closest('.' + CLASS_SLIDER_ITEM_WRAP).outerWidth(true);

        $columnWrapInner.css('transition', 'margin-left ' + ANIMATE_TIME + 'ms');
        $columnWrapInner.css('margin-left', diff - columnOuterWidth + 'px');
        window.sliderTimerId3 = setTimeout(function () {
            $columnWrapInner.css('transition', '');
        }, ANIMATE_TIME + 10);
    }

    function onColumnWrapClick(e, sliderItem) {
        if (!sliderItem) {
            clearInterval(window.sliderIntervalId);
        }
        var $sliderItem = sliderItem || $(e.target).closest('.' + CLASS_SLIDER_ITEM);
        if (!$sliderItem.length || $sliderItem.hasClass(CLASS_SLIDER_ITEM_ACTIVE)) return;
        $sliderItem.attr('data-slide', slideCount++);
        var $columnWrapInner = $sliderItem.closest('.' + CLASS_COLUMN_WRAP_INNER);
        var $sliderItemWrap = $sliderItem.closest('.' + CLASS_SLIDER_ITEM_WRAP);
        var columnOffsetLeft = $sliderItem.offset().left;
        var columnWrapOffsetLeft = $columnWrap.offset().left;
        var columnWrapInnerOffsetLeft = $columnWrapInner.offset().left;

        cloneSlideOnClick($sliderItem);

        $sliderItemWrap.attr('data-column-wrap-inner-ml', $columnWrapInner.css('margin-left'));
        $sliderItem.css('left', columnWrapOffsetLeft + 'px');
        window.sliderTimerId1 = setTimeout(function () {
            var diff = columnWrapInnerOffsetLeft - columnOffsetLeft;

            if (columnOffsetLeft !== columnWrapOffsetLeft) {
                $columnWrapInner.css('margin-left', diff + 'px');
                window.sliderTimerId2 = setTimeout(function () {
                    openSlider($sliderItem, $columnWrapInner, diff);
                }, TRANSLATE_TIME);
            } else {
                openSlider($sliderItem, $columnWrapInner, diff);
            }
        }, 100);
    }

    $columnWrap.on('click', onColumnWrapClick);

    document.addEventListener('turbolinks:before-cache', function() {
        const slides =  document.querySelector('.' + CLASS_SLIDES);
        if (!slides) return;
        slides.innerHTML = $slidesClone.html();
        $columnWrap.off('click', onColumnWrapClick);
        clearInterval(window.sliderIntervalId);
        clearTimeout(window.sliderTimerId1);
        clearTimeout(window.sliderTimerId2);
        clearTimeout(window.sliderTimerId3);
        clearTimeout(window.sliderTimerIdAutoplay);
    })
}
