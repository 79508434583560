import '../stylesheets/application.scss'
import '../javascripts/application'


try {
    const images = require.context('../images/', true);
    // const images = require.context('../images', true, /\.(jpg|jpeg|png|svg|ico)$/);
    // const imagePath = (name) => images(name, true);
} catch (e) {
    console.log(e)
}
