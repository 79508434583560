require("@rails/ujs").start();
require("turbolinks").start();
import "./pagy.js.erb";
import "./site/init.js";

// document.addEventListener('click', function (e) {
//     let langButton = document.querySelector(".js-languageButton");
//     // console.log('click')
//     if (e.target.classList.contains('js-languageButton')) {
//         langButton.parentElement.classList.toggle("active");
//     }
//   })