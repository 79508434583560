import 'slick-carousel'

function initSliderMain() {
  const $sliderTop = $('.slider-top');
  if (!$sliderTop.length) {
    return
  }
  $sliderTop.slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    fade: true,
    dots: true,
  });

  document.addEventListener('turbolinks:before-cache', () => $sliderTop.slick('unslick'))
}

function initSliderChannels() {
    const $slidesChannels = $('.slides-channels');
    if (!$slidesChannels.length) {
        return;
    }

    $slidesChannels.slick({
        centerMode: true,
        slidesToShow: 6,
        centerPadding: '222px',
        swipeToSlide: true,
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 2600,
                settings: {
                    centerPadding: '222px',
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    centerPadding: '140px',
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    centerPadding: '100px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    centerPadding: '100px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1150,
                settings: {
                    centerPadding: '100px',
                    slidesToShow: 2
                }
            },
        ]
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesChannels.slick('unslick'))
}

function initSliders() {
  initSliderMain();
  initSliderChannels();
}

export default initSliders
