export default function () {
    const calculator = document.querySelector('.js-calculator');
    if (!calculator) return;

    const calcTable = calculator.querySelector('.js-calc-table');
    const channelSelect = calculator.querySelector('.js-channel-select');
    const timingRadioAll = calculator.querySelectorAll('.js-timing-radio');
    const monthDateAll = calculator.querySelectorAll('.js-month-date');
    const monthSelect = calculator.querySelector('.js-month-select');
    const pricesDate = calculator.querySelector('.js-prices-date');
    const trDay = calculator.querySelector('.js-tr-day');
    const trDayName = calculator.querySelector('.js-tr-day-name');
    const btnReset = calculator.querySelector('.js-btn-reset');
    const btnDiscountDoubleChannel = calculator.querySelector('.js-btn-discount-double-channel');
    const calcTiming = calculator.querySelector('.js-calc-timing');

    const formPdf = calculator.querySelector('.js-pdf-form');
    const inputPriceMonth = formPdf.querySelector('.js-input-month');
    const inputPriceChannel = formPdf.querySelector('.js-input-channel');

    const resultSum = calculator.querySelector('.js-result-sum');
    const resultSumWithDiscount = calculator.querySelector('.js-result-sum-with-discount');
    const discountMain = calculator.querySelector('.js-discount-main');

    const btnToManager = calculator.querySelector('.js-btn-to-manager');

    const timeLeft = calculator.querySelector('.js-calc-time-left');
    const primeLeft = calculator.querySelector('.js-calc-prime-left');

    const btnToPdf = calculator.querySelector('.js-btn-to-pdf ');

    const CLASS_CALC_SECTOR = 'calc__sector';
    const CLASS_INPUT_PRICE = 'js-input-price';
    const CLASS_INPUT_DISCOUNT_DOUBLE_CHANNEL = 'js-input-discount-double-channel';
    const CLASS_INPUT_DISCOUNT_NEW_CLIENT = 'js-discount-new-client';
    const CLASS_INPUT_DISCOUNT_MAIN = 'js-main-discount';
    const CLASS_INPUT_PRICE_ALL_WRAP = 'js-input-price-all-wrap';

    const CLASS_TD_TIMING = 'td-timing';
    const CLASS_TD_TIMING_BOTTOM = 'td-timing-bottom';
    const CLASS_TD_COUNT = 'td-count';
    const CLASS_TD_COUNT_BOTTOM = 'td-count-bottom';
    const CLASS_TD_RESULT_BOTTOM = 'td-result-bottom';

    const CLASS_CALC_TIME = 'js-calc-time';
    const CLASS_CALC_DAY = 'js-calc-day';
    const CLASS_CALC_NUMBER = 'js-calc-number';

    const selected = {
        channelSelectValue: null,
        timingValue: null,
        monthSelectValue: "1",
        discountDoubleChannel: false,
    };
    const dataInit = {
        month: {},
        time: {},
        discount: {
            newClient: 0,
            doubleChannel: 0,
            mainDiscount: []
        },
    };

    function updateCalculator() {
        function updateTableTiming() {
            const dataTimeSelected = dataInit.time[selected.channelSelectValue] || dataInit.time[inputPriceChannel.value];
            const tbody = calcTable.querySelector('tbody');
            tbody.textContent = '';
            if (!dataTimeSelected || !dataTimeSelected.length) return;
            dataTimeSelected.forEach((dataTime) => {
                const tr = document.createElement('tr');
                tr.dataset.id = dataTime.id;
                tr.dataset.price = dataTime.price;
                tr.innerHTML = `<td class="${CLASS_CALC_TIME} time ${dataTime.prime && 'prime'}">${dataTime.time}</td>`;
                tbody.append(tr);
            });
            const tr = document.createElement('tr');
            tr.innerHTML = `<td class="td-result">Итого</td>`;
            tbody.append(tr);

        }

        function updateTh() {
            trDay.innerHTML = '<th class="th-number-title">Число</th>';
            trDayName.innerHTML = '<th class="th-day-title">День недели</th>';
            dataInit.month[selected.monthSelectValue].forEach((day, index) => {
                const thName = document.createElement('th');
                thName.className =  `th-number ${CLASS_CALC_NUMBER}`;
                thName.dataset.day = index + 1;
                thName.textContent = index + 1;
                trDay.append(thName);

                const thNum = document.createElement('th');
                thNum.className = `th-day ${CLASS_CALC_DAY}`;
                thNum.dataset.day = index + 1;
                thNum.textContent = day[index + 1];
                trDayName.append(thNum)
            });
            const th1 = document.createElement('th');
            const th2 = document.createElement('th');
            trDay.append(th1);
            trDay.append(th2);

            const th3 = document.createElement('th');
            const th4 = document.createElement('th');
            th3.classList.add('th-count-title');
            th4.classList.add('th-timing-title');
            th3.innerHTML = 'Кол-во <br>трансляций';
            th4.innerHTML = 'Общий <br>хронометраж';
            trDayName.append(th3);
            trDayName.append(th4);
        }

        function updateSector() {
            const tableTr = calcTable.querySelectorAll('tbody > tr:not(:last-of-type)');
            if (tableTr.length) {
                const monthSelect = dataInit.month[selected.monthSelectValue];
                Array.from(tableTr).forEach((tr) => {
                    monthSelect.forEach((day) => {
                        const td = document.createElement('td');
                        td.dataset.day = Object.keys(day)[0];
                        td.innerHTML = `<div class=${CLASS_CALC_SECTOR}></div>`;
                        tr.append(td);
                    });
                    const tdCount = document.createElement('td');
                    tdCount.textContent = '0';
                    tdCount.classList.add(CLASS_TD_COUNT);
                    tr.append(tdCount);
                    const tdTimingAll = document.createElement('td');
                    tdTimingAll.classList.add(CLASS_TD_TIMING);
                    tdTimingAll.textContent = '00 сек.';
                    tr.append(tdTimingAll)
                })
            }
        }

        function updateResultBottom() {
            const tableTrBottom = calcTable.querySelector('tbody > tr:last-of-type');
            if (tableTrBottom) {
                const monthSelect = dataInit.month[selected.monthSelectValue];
                monthSelect.forEach(() => {
                    const td = document.createElement('td');
                    td.classList.add(CLASS_TD_RESULT_BOTTOM);
                    td.textContent = '0';
                    tableTrBottom.append(td);
                });
                const tdCount = document.createElement('td');
                tdCount.textContent = '0';
                tdCount.classList.add(CLASS_TD_COUNT_BOTTOM);
                tableTrBottom.append(tdCount);
                const tdTimingAll = document.createElement('td');
                tdTimingAll.classList.add(CLASS_TD_TIMING_BOTTOM);
                tdTimingAll.textContent = '00 сек.';
                tableTrBottom.append(tdTimingAll)
            }
        }

        function resetInput() {
            inputPriceMonth.value = selected.monthSelectValue;
            if (selected.channelSelectValue) {
                inputPriceChannel.value = selected.channelSelectValue;
            }
            const inputPriceAll = formPdf.querySelectorAll(`.${CLASS_INPUT_PRICE}`);
            if (inputPriceAll.length) {
                Array.from(inputPriceAll).forEach((input) => {
                    input.parentNode.removeChild(input);
                })
            }
        }

        function checkLeftPosition() {
            const thead = calcTable.querySelector('thead');
            const heightThead = thead.offsetHeight;
            timeLeft.style.top = `${heightThead}px`;

            const primeTimeAll = calculator.querySelectorAll('.time.prime');
            if (primeTimeAll.length) {
                const theadTop = thead.getBoundingClientRect().top;
                const primeTimeFirstTop = primeTimeAll[0].getBoundingClientRect().top;
                const primeTimeLastBottom = primeTimeAll[primeTimeAll.length - 1].getBoundingClientRect().bottom;
                const topPrime = primeTimeFirstTop - theadTop;
                const heightPrime = primeTimeLastBottom - primeTimeFirstTop;

                primeLeft.style.top = `${Math.round(topPrime + heightPrime / 2 - 50)}px`;
            }
        }

        updateTableTiming();
        updateTh();
        updateSector();
        updateResultBottom();
        resetInput();
        checkLeftPosition();
    }

    function initCalculator() {
        function initMonth() {
            Array.from(monthDateAll).forEach((monthDate) => {
                try {
                    dataInit.month[monthDate.dataset.monthNumber] = JSON.parse(monthDate.dataset.days);
                } catch (e) {
                    console.error(e)
                }
            })
        }

        function initTime() {
            const timeAll = pricesDate.querySelectorAll('*');
            Array.from(timeAll).forEach((time) => {
                if (!dataInit.time[time.dataset.name]) {
                    dataInit.time[time.dataset.name] = []
                }
                dataInit.time[time.dataset.name].push({
                    id: time.dataset.id,
                    price: time.dataset.price,
                    prime: time.dataset.prime,
                    time: time.dataset.time,
                })
            })
        }

        function initChannelSelect() {
            channelSelect.addEventListener('change', (e) => {
                channelSelect.classList.remove('error');
                selected.channelSelectValue = e.target.value;
                updateCalculator();
            })
        }

        function initTimingRadio() {
            Array.from(timingRadioAll).forEach((timingRadio) => {
                timingRadio.addEventListener('change', (e) => {
                    calcTiming.classList.remove('error');
                    selected.timingValue = e.target.value
                })
            })
        }

        function initMonthSelect() {
            monthSelect.addEventListener('change', (e) => {
                selected.monthSelectValue = e.target.value;
                updateCalculator();
            })
        }

        function checkIsValid() {
            return selected.channelSelectValue && selected.timingValue;
        }

        function updateResult() {
            function updateSum() {
                const inputPriceAll = formPdf.querySelectorAll(`.${CLASS_INPUT_PRICE}`);
                let sum = 0;
                let sumWithDiscount = 0;
                let mainDiscountPercent = 0;

                if (inputPriceAll && inputPriceAll.length) {
                    sum = Array.from(inputPriceAll).reduce((sum, input) => {
                        return sum + Math.round(parseInt(input.dataset.price, 10) / 60 * parseInt(input.dataset.sec, 10))
                    }, 0);

                    sumWithDiscount = sum;

                    dataInit.discount.mainDiscount.forEach((mainDiscountItem) => {
                        if (sumWithDiscount > parseInt(mainDiscountItem.sum, 10) && parseInt(mainDiscountItem.percent, 10) > mainDiscountPercent) {
                            mainDiscountPercent = mainDiscountItem.percent;
                        }
                    });
                    sumWithDiscount = Math.round(sumWithDiscount - sumWithDiscount / 100 * +mainDiscountPercent);

                    if (selected.discountDoubleChannel) {
                        sumWithDiscount = Math.round(sumWithDiscount - sumWithDiscount / 100 * parseInt(dataInit.discount.doubleChannel, 10));
                    }
                    sumWithDiscount = Math.round(sumWithDiscount - sumWithDiscount / 100 * parseInt(dataInit.discount.newClient, 10));
                }

                discountMain.textContent = mainDiscountPercent.toString();
                resultSum.textContent = sum.toString();
                resultSumWithDiscount.textContent = sumWithDiscount.toString();
            }

            function updateCounterVertical() {
                const tableTr = calcTable.querySelectorAll('tbody > tr');
                const tdTimingAll = calculator.querySelector(`.${CLASS_TD_TIMING_BOTTOM}`);
                let timingAll = 0;

                Array.from(tableTr).forEach((trItem) => {
                    const sectorAll = trItem.querySelectorAll(`.${CLASS_CALC_SECTOR}`);
                    if (!sectorAll) return;
                    const sectorSelected = Array.from(sectorAll).filter((sectorItem) => !!sectorItem.dataset.timing);
                    const tdTiming = trItem.querySelector(`.${CLASS_TD_TIMING}`);
                    if (tdTiming) {
                        let timing = 0;
                        sectorSelected.forEach((sector) => {
                            timing += +sector.dataset.timing;
                        });

                        timingAll += +timing;
                        if (!timing) {
                            timing = '00';
                        }
                        tdTiming.textContent = timing.toString() + ' сек';
                    }
                    const tdCounter = trItem.querySelector(`.${CLASS_TD_COUNT}`);
                    if (tdCounter) {
                        tdCounter.textContent = (sectorSelected.length).toString();
                    }
                });
                if (!timingAll) {
                    timingAll = '00';
                }
                tdTimingAll.textContent = timingAll.toString() + ' сек';
            }

            function updateCounterHorizontal() {
                function updateCountAll() {
                    const tdCountBottom = calcTable.querySelector(`.${CLASS_TD_COUNT_BOTTOM}`);
                    const tdCountAll = calcTable.querySelectorAll(`.${CLASS_TD_COUNT}`);
                    const countAll = Array.from(tdCountAll).reduce((sum, tdCountItem) => {
                        return sum + +tdCountItem.textContent;
                    }, 0);
                    tdCountBottom.textContent = countAll.toString();
                }

                function updateCount() {
                    const trAll = calcTable.querySelectorAll('tbody > tr');
                    const trLast = calcTable.querySelector('tbody > tr:last-of-type');
                    const trLength = trAll[0].querySelectorAll('td').length - 3;

                    for (let i = 0; i < trLength; i++) {
                        let sum = 0;
                        for (let j = 0; j < trAll.length - 1; j++) {
                            const timing = trAll[j].querySelectorAll('td')[i + 1].querySelector('.calc__sector').dataset.timing;
                            if (timing) {
                                sum++;
                            }
                        }
                        trLast.querySelectorAll(`.${CLASS_TD_RESULT_BOTTOM}`)[i].textContent = sum.toString();
                    }
                }

                updateCountAll();
                updateCount()
            }

            updateSum();
            updateCounterVertical();
            updateCounterHorizontal();
        }

        function initCalcTable() {
            function showErrorChannelSelect() {
                channelSelect.classList.add('error');
            }

            function showErrorTiming() {
                calcTiming.classList.add('error');
            }

            function checkBefore(func) {
                if (checkIsValid()) {
                    func()
                } else {
                    if (!selected.channelSelectValue && !selected.timingValue) {
                        showErrorChannelSelect();
                        showErrorTiming();
                        window.alert('Необходимо выбрать телеканал и хронометраж ролика');
                    } else {
                        if (!selected.channelSelectValue) {
                            showErrorChannelSelect();
                            window.alert('Необходимо выбрать телеканал');
                        } else {
                            showErrorTiming();
                            window.alert('Необходимо выбрать хронометраж ролика');
                        }
                    }
                }
            }

            function changeSector(sector) {
                const sectorTd = sector.closest('td');
                const sectorTr = sectorTd.closest('tr');
                let inputPrice = formPdf.querySelector(`input[day-time-id="${sectorTd.dataset.day}-${sectorTr.dataset.id}"`);
                if (sector.dataset.timing === selected.timingValue) {
                    sector.dataset.timing = '';
                    inputPrice.parentNode.removeChild(inputPrice);
                } else {
                    sector.dataset.timing = selected.timingValue;

                    if (!inputPrice) {
                        inputPrice = document.createElement('input');
                        inputPrice.setAttribute('type', 'hidden');
                        inputPrice.setAttribute('day-time-id', `${sectorTd.dataset.day}-${sectorTr.dataset.id}`);
                        inputPrice.dataset.day = sectorTd.dataset.day;
                        inputPrice.dataset.price = sectorTr.dataset.price;
                        inputPrice.dataset.timeId = sectorTr.dataset.id;
                        inputPrice.classList.add(CLASS_INPUT_PRICE);
                        formPdf.append(inputPrice);
                    }
                    inputPrice.setAttribute('name', `price[days[${sectorTd.dataset.day}][${sectorTr.dataset.id}]]`);
                    inputPrice.value = selected.timingValue;
                    inputPrice.dataset.sec = selected.timingValue;
                }
                updateResult();
            }

            function changeOnTime(timeTd) {
                const sectorTr = timeTd.closest('tr');
                const sectorAll = sectorTr.querySelectorAll(`.` + CLASS_CALC_SECTOR)
                sectorAll.forEach((sectorItem) => {
                    changeSector(sectorItem);
                })
            }
            function changeOnDay(dayTh) {
                const dayData = dayTh.dataset.day
                const tdAllByDay = calcTable.querySelectorAll(`td[data-day="${dayData}"]`)
                tdAllByDay.forEach((tdItem) => {
                    const sector = tdItem.querySelector(`.${CLASS_CALC_SECTOR}`);
                    if (!sector) return;
                    changeSector(sector);
                })
            }

            calcTable.addEventListener('click', (e) => {
                const target = e.target;
                if (target && target.classList.contains(CLASS_CALC_SECTOR)) {
                    checkBefore(changeSector.bind(null, target))
                }
                if (target && target.classList.contains(CLASS_CALC_TIME)) {
                    checkBefore(changeOnTime.bind(null, target))
                }
                if (target && (target.classList.contains(CLASS_CALC_DAY) || target.classList.contains(CLASS_CALC_NUMBER))) {
                    checkBefore(changeOnDay.bind(null, target))
                }
            })

        }

        function initBtnReset() {
            btnReset.addEventListener('click', (e) => {
                e.preventDefault();
                updateCalculator();
                updateResult();
            })
        }

        function initBtnDiscountDoubleChannel() {
            btnDiscountDoubleChannel.addEventListener('click', (e) => {
                e.preventDefault();
                const newValue = !selected.discountDoubleChannel;
                const inputDiscountDoubleChannel = formPdf.querySelector(`.${CLASS_INPUT_DISCOUNT_DOUBLE_CHANNEL}`);
                if (inputDiscountDoubleChannel) {
                    inputDiscountDoubleChannel.value = newValue;
                }

                selected.discountDoubleChannel = newValue;
                e.target.classList.toggle('active');
                updateResult();
            })
        }

        function initBtnToManager() {
            btnToManager.addEventListener('click', (e) => {
                e.preventDefault();

                const inputPriceAll = formPdf.querySelectorAll(`.${CLASS_INPUT_PRICE}`);
                const inputPriceAllWrap = document.querySelector(`.${CLASS_INPUT_PRICE_ALL_WRAP}`);
                if (inputPriceAll.length) {
                    if (!inputPriceAllWrap) return;
                    inputPriceAllWrap.innerHTML = '';
                    Array.from(inputPriceAll).forEach((inputPriceItem) => {
                        const cloneInput = inputPriceItem.cloneNode();
                        inputPriceAllWrap.append(cloneInput);
                    });
                    try {
                        const inputPriceMonthClone = inputPriceMonth.cloneNode();
                        const inputPriceChannelClone = inputPriceChannel.cloneNode();
                        const inputDiscountDoubleChannel = formPdf.querySelector(`.${CLASS_INPUT_DISCOUNT_DOUBLE_CHANNEL}`);
                        const inputDiscountDoubleChannelClone = inputDiscountDoubleChannel.cloneNode();

                        inputPriceAllWrap.append(inputPriceMonthClone);
                        inputPriceAllWrap.append(inputPriceChannelClone);
                        inputPriceAllWrap.append(inputDiscountDoubleChannelClone);

                    } catch (e) {
                        console.error(e)
                    }
                    $.magnificPopup.open({
                        items: {
                            src: $('#popup-request-ad-placement'),
                        },
                        type: 'inline',
                    })

                } else {
                    alert('Необходимо заполнить данные в таблице');
                }
            })
        }

        function initBtnToPdf() {
            btnToPdf.addEventListener('click', (e) => {
                const inputPriceAll = formPdf.querySelectorAll(`.${CLASS_INPUT_PRICE}`);
                if (!inputPriceAll.length) {
                    e.preventDefault();
                    alert('Необходимо заполнить данные в таблице');
                }
            })
        }

        function initDiscount() {
            try {
                dataInit.discount.newClient = calculator.querySelector(`.${CLASS_INPUT_DISCOUNT_NEW_CLIENT}`).dataset.discount;
                dataInit.discount.doubleChannel = calculator.querySelector(`.${CLASS_INPUT_DISCOUNT_DOUBLE_CHANNEL}`).dataset.discount;
                const mainDiscountAll = calculator.querySelectorAll(`.${CLASS_INPUT_DISCOUNT_MAIN} *`);
                Array.from(mainDiscountAll).forEach((mainDiscountItem) => {
                    const discount = {
                        percent: mainDiscountItem.dataset.percent,
                        sum: mainDiscountItem.dataset.sum
                    };
                    dataInit.discount.mainDiscount.push(discount)
                })
            } catch (e) {
                console.error(e)
            }
        }

        initMonth();
        initTime();
        initChannelSelect();
        initTimingRadio();
        initMonthSelect();
        initCalcTable();
        initBtnReset();
        initBtnDiscountDoubleChannel();
        initBtnToManager();
        initDiscount();
        initBtnToPdf();
    }

    initCalculator();
    updateCalculator();
}
