export default function() {
    const btnPrev = document.querySelector('.js-btn-prev');
    const btnNext = document.querySelector('.js-btn-next');
    const timelineInner = document.querySelector('.js-timeline-inner');

    if (!btnNext || !timelineInner) return;
    const lastItem = timelineInner.querySelector('.timeline__item:last-of-type');
    let translateTimeline = 0;
    const TRANSLATE_PX = 500;

    const maxTranslate = lastItem.getBoundingClientRect().right - window.innerWidth || document.documentElement.clientWidth;

    function onBtnPrevClick() {
        translateTimeline += TRANSLATE_PX;
        timelineInner.style.transform = `translateX(${translateTimeline}px)`;
        if (translateTimeline >= 0) {
            btnPrev.classList.add('disabled');
        }
        if (btnNext.classList.contains('disabled')) {
            btnNext.classList.remove('disabled');
        }
    }
    function onBtnNextClick() {
        translateTimeline += -(TRANSLATE_PX);
        timelineInner.style.transform = `translateX(${translateTimeline}px)`;
        if (btnPrev.classList.contains('disabled')) {
            btnPrev.classList.remove('disabled');
        }
        if (Math.abs(translateTimeline) > maxTranslate) {
            btnNext.classList.add('disabled');
        }
    }

    btnPrev.addEventListener('click', onBtnPrevClick);
    btnNext.addEventListener('click', onBtnNextClick);
}
