import magnificPopup from 'magnific-popup'

const popupInit = () => {
  $('.btn-popup').magnificPopup({
    type: 'inline',
    closeOnBgClick: false,
    callbacks: {
      beforeOpen() {
        $('body').addClass('mfp-active')
      },
      beforeClose() {
        $('body').removeClass('mfp-active')
      },
    },
  })

  $('.btn-popup-closing').magnificPopup({
    type: 'inline',
    callbacks: {
      beforeOpen() {
        $('body').addClass('mfp-active')
      },
      beforeClose() {
        $('body').removeClass('mfp-active')
      },
    },
  })

  $('.btn-popup-ajax').magnificPopup({
    type: 'ajax',
    callbacks: {
      beforeOpen() {
        $('body').addClass('mfp-active')
      },
      beforeClose() {
        $('body').removeClass('mfp-active')
      },
    },
  })

  document.addEventListener('turbolinks:before-cache', () => {
    $.magnificPopup.close()
  })

  // Кнопка закрытия поповера
  $('body').on('click', '.btn-close-popup', () => {
    $.magnificPopup.close()
  })

  // Focus textarea
  $('.js-btn-comments').click(() => {
    $.magnificPopup.close()
    $('.js-focus-area').focus()
  })

  $('.popup-youtube, .popup-video, .popup-gmaps').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,

    fixedContentPos: false,
  })

  $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    },
  })
}

export default popupInit
