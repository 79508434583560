import './polyfills.js'
import SliderSlickInit from './sliderSlick.js'
import SliderInit from './slider.js'
import TimelineInit from './timeline.js'
import PopupInit from './popupInit.js'
import CalculatorInit from './calculator.js'
import SelectInit from './select.js'
import FormInit from './form.js'
import Flash_notice from './flash_notice'

document.addEventListener('turbolinks:load', function() {
    new Flash_notice();
    PopupInit();
    SliderSlickInit();
    SliderInit();
    TimelineInit();
    CalculatorInit();
    SelectInit();
    FormInit();
});
